import React, { useState } from 'react'
import { Box, FormLabel, Grid, IconButton, Menu, MenuItem, Select, Typography } from '@mui/material'
import { inputStyles, labelStyles } from '../certification/EditCertificate'
import dayjs from 'dayjs'
import { ChevronLeft, ChevronRight, FileDownload } from '@mui/icons-material'
import { DatePicker } from '@mui/x-date-pickers'
import { downLoadFiles } from '../middleware/auth'
import { checkTenantId } from '../../helper/CommonAPI'

const ExcelAndPdfDownload = ({ baseUrl, pdf, excel, type, sortingOnColumnOptions }) => {
    const [reportAnchor, setReportAnchor] = useState(null)
    const initialFil = {
        duration: 'monthly',
        startDate: '',
        endDate: '',
        month: dayjs().month(),
        sortingOnColumn: "",
        sortingType: "asc",
        donor_type: "GENERAL"
    }
    const [searchParams, setSearchParams] = useState(initialFil)

    const handleApplyFilter = (values, downloadFormat) => {
        let filterurl = ''
        if (type === 'Issue_register_paediatric') {
            filterurl = filterurl + `&blood_request_type=2`
        }
        if (type === 'Issue_register_patient') {
            filterurl = filterurl + `&blood_request_type=1`
        }
        if (type === 'Issue_register_paediatric' || type === 'Issue_register_patient') {
            filterurl = filterurl + `&status=1`
        }

        if (values?.sortingOnColumn && values?.sortingType) {
            filterurl = filterurl + `&${values?.sortingOnColumn}=${values?.sortingType}`
        }
        if (type === "donor_register" && values?.donor_type) {
            filterurl = filterurl + `&donor_type=${values?.donor_type}`
        }
        if (values?.startDate) {
            filterurl = filterurl + `${type === "Issue_register_paediatric" || type === "Issue_register_patient" ? "&issue_start=" : "&startDate="}${values?.startDate}`
        }
        if (values?.endDate) {
            filterurl = filterurl + `${type === "Issue_register_paediatric" || type === "Issue_register_patient" ? "&issue_end=" : "&endDate="}${values?.endDate}`
        }

        if (values?.duration === 'monthly') {
            const today = dayjs().format('YYYY-MM-DD')
            const firstDate =
                values?.month || values?.month === 0 ? dayjs().month(values.month).startOf('month').format('YYYY-MM-DD') : ''
            const lastDate =
                values?.month || values?.month === 0
                    ? dayjs().month(values.month).isSame(dayjs(), 'month')
                        ? today
                        : dayjs().month(values.month).endOf('month').format('YYYY-MM-DD')
                    : ''

            filterurl = filterurl + `${type === "Issue_register_paediatric" || type === "Issue_register_patient" ? "&issue_start=" : "&startDate="}${firstDate}${type === "Issue_register_paediatric" || type === "Issue_register_patient" ? "&issue_end=" : "&endDate="}${lastDate}`
        }
        downLoadFiles(`/${baseUrl}?${downloadFormat}=true${filterurl}`, type)
    }

    const openReportMenu = event => {
        setReportAnchor(event.currentTarget)
    }

    const closeBillMenu = () => {
        setReportAnchor(null)
    }

    function handleAllChanges(e) {
        if (e.target.value === 'monthly') {
            setSearchParams({
                ...searchParams,
                [e.target.name]: e.target.value,
                startDate: '',
                endDate: ''
            })
        } else {
            setSearchParams({
                ...searchParams,
                [e.target.name]: e.target.value
            })
        }
    }

    return (
        <>
            <button
                type='button'
                className='btn gradient-btn'
                // onClick={handlebillingReport}
                onClick={openReportMenu}
            >
                <FileDownload fontSize='small' /> Report
            </button>
            <Menu
                open={Boolean(reportAnchor)}
                anchorEl={reportAnchor}
                onClose={closeBillMenu}
                keepMounted
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}

            //TransitionComponent={Slide}
            >
                <div className='fltr-billing-container'>
                    <Grid sx={{ maxWidth: '480px', padding: '16px' }} container spacing={2}>
                        {sortingOnColumnOptions && sortingOnColumnOptions?.length > 0 ?
                            <>

                                {type === "donor_register" ? <>
                                    <Grid item xs={12} sm={4}>
                                        <FormLabel sx={labelStyles}>Register Type</FormLabel>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <Select
                                            sx={{ height: '42px', ...inputStyles }}
                                            fullWidth
                                            name='donor_type'
                                            value={searchParams?.donor_type}
                                            onChange={e => handleAllChanges(e)}
                                        >
                                            <MenuItem value={'GENERAL'}>General</MenuItem>
                                            <MenuItem value={'SDP'}>SDP</MenuItem>
                                            <MenuItem value={'DEFER'}>DEFERRAL</MenuItem>
                                        </Select>
                                    </Grid>
                                </> : null}

                                <Grid item xs={12} sm={4}>
                                    <FormLabel sx={labelStyles}>Sorting on Column</FormLabel>
                                </Grid>


                                {searchParams?.donor_type === "DEFER" ?
                                    <Grid item xs={12} sm={8}>
                                        <Select
                                            sx={{ height: '42px', ...inputStyles }}
                                            fullWidth
                                            name='sortingOnColumn'
                                            value={searchParams?.sortingOnColumn}
                                            onChange={e => handleAllChanges(e)}
                                        >
                                            <MenuItem value='orderByName'>Donor Name</MenuItem>
                                            <MenuItem value='orderByDontionDate'>Donation Date</MenuItem>

                                        </Select>
                                    </Grid> :
                                    <Grid item xs={12} sm={8}>
                                        <Select
                                            sx={{ height: '42px', ...inputStyles }}
                                            fullWidth
                                            name='sortingOnColumn'
                                            value={searchParams?.sortingOnColumn}
                                            onChange={e => handleAllChanges(e)}                                    >
                                            {sortingOnColumnOptions && sortingOnColumnOptions.map((list, i) => (
                                                <MenuItem value={list?.value} key={i}>{list.label}</MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                }



                                {searchParams?.sortingOnColumn ? <>
                                    <Grid item xs={12} sm={4}>
                                        <FormLabel sx={labelStyles}>Sorting by</FormLabel>
                                    </Grid>
                                    <Grid item xs={12} sm={8}>
                                        <Select
                                            sx={{ height: '42px', ...inputStyles }}
                                            fullWidth
                                            name='sortingType'
                                            value={searchParams?.sortingType}
                                            onChange={e => handleAllChanges(e)}
                                        >
                                            <MenuItem value={'asc'}>Ascending</MenuItem>
                                            <MenuItem value={'desc'}>Descending</MenuItem>
                                        </Select>
                                    </Grid>
                                </> : null}
                            </>
                            : null}

                        <Grid item xs={12} sm={4}>
                            <FormLabel sx={labelStyles}>Duration</FormLabel>
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <Select
                                sx={{ height: '42px', ...inputStyles }}
                                fullWidth
                                select
                                placeholder='duration'
                                name='duration'
                                value={searchParams?.duration}
                                onChange={handleAllChanges}
                            >
                                <MenuItem value='monthly'>Monthly</MenuItem>
                                {/* <MenuItem value="Half_year">Last 6 Months</MenuItem> */}
                                <MenuItem value='custom'>Custom</MenuItem>
                            </Select>
                            {searchParams?.duration === 'monthly' ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        background: '#fff',
                                        borderRadius: 2
                                    }}
                                >
                                    <IconButton
                                        onClick={() => {
                                            if (searchParams?.month > 0) {
                                                setSearchParams({
                                                    ...searchParams,
                                                    month: searchParams?.month - 1
                                                })
                                            }
                                        }}
                                    >
                                        <ChevronLeft />
                                    </IconButton>
                                    <Typography>
                                        <DatePicker
                                            views={['month']}
                                            disableFuture
                                            value={dayjs().month(searchParams?.month)}
                                            format='MMMM-YYYY'
                                            onChange={value => {
                                                setSearchParams({
                                                    ...searchParams,
                                                    month: dayjs(value).month()
                                                })
                                            }}
                                            sx={{
                                                ...inputStyles,
                                                background: 'transparent !important',
                                                border: 0,
                                                '& fieldset': { border: '0 !important' },
                                                '& .MuiOutlinedInput-root': {
                                                    p: '0 !important'
                                                }
                                            }}
                                            slotProps={{
                                                textField: {
                                                    size: 'small',
                                                    fullWidth: true
                                                }
                                            }}
                                        />
                                    </Typography>
                                    <IconButton
                                        onClick={() => {
                                            if (searchParams?.month < dayjs().month()) {
                                                setSearchParams({
                                                    ...searchParams,
                                                    month: searchParams?.month + 1
                                                })
                                            }
                                        }}
                                    >
                                        <ChevronRight />
                                    </IconButton>
                                </Box>
                            ) : null}
                            {searchParams?.duration === 'custom' ? (
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        background: '#fff',
                                        gap: 2,
                                        pt: 2
                                    }}
                                >
                                    <DatePicker
                                        sx={{ ...inputStyles, maxWidth: '50' }}
                                        fullWidth
                                        type='date'
                                        format='YYYY-MM-DD'
                                        name='startDate'
                                        disableFuture
                                        value={searchParams?.startDate || null}
                                        onChange={value =>
                                            setSearchParams({
                                                ...searchParams,
                                                startDate: dayjs(value).format('YYYY-MM-DD')
                                            })
                                        }
                                    />

                                    <DatePicker
                                        sx={{ ...inputStyles, maxWidth: '50' }}
                                        fullWidth
                                        type='date'
                                        disableFuture
                                        format='YYYY-MM-DD'
                                        name='endDate'
                                        value={searchParams?.endDate || null}
                                        onChange={value =>
                                            setSearchParams({
                                                ...searchParams,
                                                endDate: dayjs(value).format('YYYY-MM-DD')
                                            })
                                        }
                                    />
                                </Box>
                            ) : null}
                        </Grid>

                        <Grid item xs={12} display='flex' justifyContent='flex-end' gap={2}>
                            <button
                                className='btn backBtn '
                                onClick={() => {
                                    setSearchParams(initialFil)
                                }}
                            >
                                Reset
                            </button>

                            {excel ? (
                                <button className='btn gradient-btn' onClick={() => handleApplyFilter(searchParams, 'downloadExcel')}>
                                    Download Excel
                                </button>
                            ) : null}

                            {pdf ? (
                                <button className='btn gradient-btn' onClick={() => handleApplyFilter(searchParams, 'downloadPdf')}>
                                    Download pdf
                                </button>
                            ) : null}
                        </Grid>
                    </Grid>
                </div>
            </Menu>
        </>
    )
}

export default ExcelAndPdfDownload
