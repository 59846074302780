import axios from 'axios'
import { API_URL } from '../../helper/AxiosHelper'
import { downloadFile } from '../issue-certification'
import { toast } from 'react-toastify'

export const isLogin = () => {
  const authInfo = localStorage.getItem('authInfo') || '{}'
  const auth = JSON.parse(authInfo)
  if (Object.keys(auth).length > 0) {
    return true
  }
  return false
}

export const getName = () => {
  const authInfo = localStorage.getItem('authInfo') || '{}'
  return JSON.parse(authInfo)?.name
}
export const getId = () => {
  const authInfo = localStorage.getItem('authInfo') || '{}'
  return JSON.parse(authInfo)?.tenant_id
}

export const getToken = () => {
  const authInfo = localStorage.getItem('authInfo') || '{}'
  return JSON.parse(authInfo)?.token
}

export const getEntityInfo = () => {
  const authInfo = localStorage.getItem('authInfo') || '{}'
  return JSON.parse(authInfo)?.tenantInfo?.type
}

export const getTenatName = () => {
  const authInfo = localStorage.getItem('authInfo') || '{}'
  return JSON.parse(authInfo)?.tenantInfo?.tenant
}
export const getTenant = () => {
  const authInfo = localStorage.getItem('authInfo') || '{}'
  return JSON.parse(authInfo)?.tenantInfo
}
export const getTenatLicense = () => {
  const authInfo = localStorage.getItem('authInfo') || '{}'
  return JSON.parse(authInfo)?.tenantInfo?.licence_number
}

export const getTenatNameSans = () => {
  const tenant = localStorage.getItem('tenant') || {}
  if (Object.keys(tenant).length > 0) {
    let name = JSON.parse(tenant)?.name
    var y = name.split(' ').slice(0, 2).join(' ')
    return y
  }
  return ''
}
export const getTenatIdSans = () => {
  const tenant = localStorage.getItem('tenant') || {}
  if (Object.keys(tenant).length > 0) {
    return JSON.parse(tenant)?.id
  }
  return ''
}

export const getDonorNameSans = () => {
  const tenant = localStorage.getItem('donor') || {}
  if (Object.keys(tenant).length > 0) {
    let name = JSON.parse(tenant)?.name
    var y = name.split(' ').slice(0, 2).join(' ')
    return y
  }
  return ''
}
export const getDonorIdSans = () => {
  const tenant = localStorage.getItem('donor') || {}
  if (Object.keys(tenant).length > 0) {
    return JSON.parse(tenant)?.id
  }
  return ''
}

export const getTenantsId = () => {
  const tenant = localStorage.getItem('tenant') || {}
  if (Object.keys(tenant).length > 0) {
    return JSON.parse(tenant)?.id
  }
  return ''
}

// ** Returns initials from string
export const getInitials = string => string?.split(/\s/).reduce((response, word) => (response += word.slice(0, 1)), '')
export const getThreeInitials = string => {
  if (!string) return '';
  const initials = string
  .split(/\s+/)
  .reduce((response, word) => (response += word.slice(0, 1)), '')
  .toUpperCase();
  
return initials.length > 3 ? initials.slice(0, 3) : initials;
}

export const getTwoInitials = string => {
  if (!string) return '';
  
  const initials = string
    .split(/\s+/)
    .reduce((response, word) => (response += word.slice(0, 1)), '')
    .toUpperCase();
    
  return initials.length > 2 ? initials.slice(0, 2) : initials;
};

export const checkPermission = value => {
  const authInfo = localStorage.getItem('authInfo') || '{}'
  const permission = JSON.parse(authInfo).permission
  if (permission && permission.length > 0) {
    return permission.indexOf(value) === -1 ? false : true
  }
  return false
}

export const advanceFeatures = value => {
  if (!value) return false
  const authInfo = JSON.parse(localStorage.getItem('authInfo'))
  const permission = authInfo.advance_feature
  if (permission && permission.length > 0) {
    const exists = permission.some(item => item.feature_name === value && item.is_active === true)

    return exists
  }
  return false
}

export function addDonorSide(item) {
  localStorage.setItem('donor', JSON.stringify(item))
  document.getElementById('drop-down-donor').classList.remove('drop-down-menu-clicked')
}

export async function getPinCodeData(pin) {
  if (!pin) {
    return []
  }

  try {
    const res = await axios.get(`https://api.postalpincode.in/pincode/${pin}`)
    if (res.data) {
      let address = res?.data[0]?.PostOffice
      if (address?.length > 0) {
        return address[0]
      }
      return
    }
  } catch (e) {
    console.log(e)
  }
}

export function downLoadFiles(url, filename) {
  const toastId = toast.loading('Downloading file, please wait')
  axios({
    url: API_URL + url,
    headers: {
      Authorization: `Bearer ${getToken()}`
    },
    method: 'GET',
    responseType: 'blob'
  })
    .then(response => {
      const blob = new Blob([response.data], {
        type: response.headers['content-type']
      })
      const url = window.URL.createObjectURL(blob)
      downloadFile(url, filename, toastId)
    })
    .catch(error => {
      toast.update(toastId, {
        render: 'File download failed. Please try again later.',
        type: 'error',
        isLoading: false,
        autoClose: 2000
      })
      console.error('Axios error:', error)
    })
}
