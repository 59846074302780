import React, { useState, useEffect, useRef, useCallback } from 'react'
import Loader from '../shared/Loader'
import AxiosHelper from '../../helper/AxiosHelper'
import PaginationLink from '../pagination/PaginationLink'
import { useHistory } from 'react-router-dom'
import './Donors.css'
import BreadCrumb from '../common/BreadCrumb'
import { Select, Menu, FormLabel, MenuItem, TextField, Checkbox, ListItemText, Collapse } from '@mui/material'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'

import { checkPermission } from '../middleware/auth'
import AllDonorsTable from './AllDonorsTable'
import ScrollCard from '../shared/ScrollCard'
import { labelStyles } from '../certification/EditCertificate'
import TextButton from '../shared/components/TextButton'
import { checkTenantId, debouncing, getLocalStorageItem, setLocalStorageItem } from '../../helper/CommonAPI'
import ExcelAndPdfDownload from '../print-components/ExcelAndPdfDownload'
import { Add } from '@mui/icons-material'
export const radioStyles = {
  '&.MuiCheckbox-indeterminate,&.Mui-checked': {
    color: '#214F8A'
  }
}

export const inputStyles = {
  background: '#f5f5f5',
  border: '1px solid #d1d5db',
  borderRadius: '0.5rem',
  height: '36px',
  '& .MuiOutlinedInput-notchedOutline': {
    borderWidth: 0
  },
  '& .MuiInputBase-input': {
    height: 25
  },
  '& .MuiInputBase-root': {
    height: 36
  },
  '&:focus-within fieldset, &:focus-visible fieldset': {
    borderWidth: '1px!important',
    borderRadius: '0.5rem'
  }
}

const breadcrumbRoute = [
  {
    path: '/dashboard',
    label: 'Dashboard'
  },
  {
    path: '/donors',
    label: 'All Donors'
  }
]

const searchSelectBox = {
  borderRadius: '8px 0px 0px 8px',
  background: 'linear-gradient(90deg, #204E89 0.24%, #12B9AA 99.78%)',
  height: 'inherit',
  marginRight: '8px',
  color: '#ffffff',
  width: '238px'
}

export const processArr = [
  { id: '1', name: 'Total Deferred' },
  { id: '2', name: 'Pending Medical Screening' },
  { id: '3', name: 'Pending Assign Blood Bag' },
  { id: '4', name: 'Pending TTI Testing' },
  { id: '5', name: 'Pending Blood Component Inventory' }
]

const sortingOnColumnOptions=[
  {label:"Blood Bag Number",value:'orderByBagNumber'},
  {label:"Donor Name",value:'orderByName'},
  {label:"Donation Date",value:'orderByDontionDate'},
  {label:"Donor Id",value:'orderById'},
  {label:"Aadhaar",value:'orderByAdhar'},
  {label:"Donation Type",value:'orderByDonationType'},
  {label:"Mobile Number",value:'orderByMobile'},
]

const Donors = () => {
  const [data, setData] = useState([]);
  const [campList, setCampList] = useState([]);
  const [pageData, setPageData] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [searchbox, setsearchbox] = useState("");
  const [isFiltered, setIsFiltered] = useState(false);
  const [custumUrl, setCustomUrl] = useState("")
  const [currentFilter, setcurrentFilter] = useState(2);
  const [anchor, setAnchor] = useState(null);
  const [districts, setDistricts] = useState([]);
  const [searchIsDefer, setSearchIsdefer] = useState("");
  const [searchGender, setSearchGender] = useState("");
  const [searchSite, setSearchSite] = useState("");
  const [searchSelect, setSearchSelect] = useState(getLocalStorageItem("donorSearchBy", "bag_number"))
  const [searchDonationType, setSearchDonationType] = useState("");
  const [searchLocation, setSearchLocation] = useState([]);
  const [searchProcessStatus, setSearchPrecessStatus] = useState([]);
  const [searchDateFrom, setSearchDateFrom] = useState("");
  const [searchDateTo, setSearchDateTo] = useState("");
  const [searchCamp, setSearchCamp] = useState("");
  const [searchAgeFrom, setSearchAgeFrom] = useState("");
  const [searchAgeTo, setSearchAgeTo] = useState("");
  const [currSearchPrams, setCurrSearchPrams] = useState({});
  const [closefltr, setClosefltr] = useState(false);
  const [sorting, setSorting] = useState([])
  const resetFilterMain = () => {
    resetFilterValues()
    getDonors(`donor?page=1`)
  }

  const resetFilterValues = () => {
    setSearchIsdefer('')
    setSearchAgeFrom('')
    setSearchAgeTo('')
    setSearchDateFrom('')
    setSearchDateTo('')
    setSearchDonationType('')
    setSearchGender('')
    setSearchLocation([])
    setSearchPrecessStatus([])
    setSearchSite('')
    setSearchCamp('')
    setCurrSearchPrams({})
    //setsearchbox("");
    setIsFiltered(false)
  }
  const reff = useRef(null)
  const history = useHistory()

  const getDistict = () => {
    AxiosHelper.get('address/district/29')
      .then(response => {
        setDistricts(response?.data?.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const getCamps = () => {
    let url = 'donor/camp-list'
    url = checkTenantId(url)
    AxiosHelper.get(url)
      .then(response => {
        setCampList(response?.data?.data)
      })
      .catch(error => {
        console.log(error)
      })
  }

  const getDonors = url => {
    setLoading(true)
    url = checkTenantId(url, 'type')
    AxiosHelper.get(url)
      .then(response => {
        setData(response.data.data)
        setPageData(response.data.data.pagination)
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        console.log(error)
      })
  }

  useEffect(() => {
    getDistict()
    getCamps()
  }, [])

  useEffect(() => {
    handleApplyFilter()
  }, [sorting])

  useEffect(() => {
    handleApplyFilter()
  }, [sorting])

  useEffect(() => {
    setSearchIsdefer('')
    setSearchAgeFrom('')
    setSearchAgeTo('')
    setSearchDateFrom('')
    setSearchDateTo('')
    setSearchDonationType('')
    setSearchGender('')
    setSearchLocation([])
    setSearchPrecessStatus([])
    setSearchSite('')
    setSearchCamp('')
    // setSearchText("");
    setsearchbox("");
    setIsFiltered(false);
    handleCustomRequests(1);
    setCustomUrl("")
  }, [currentFilter]);

  const handleCustomRequests = (page) => {
    
    if (currentFilter !== 0) {
      getDonors(`donor?page=${page}&status=` + currentFilter)
      return
    }
    if (isFiltered) {
      let url = `donor?page=${page}` + custumUrl
      getDonors(url)
      return
    }
    if (searchbox?.length > 0) {
      getDonors(`donor?page=${page}&key=${searchSelect}&value=${searchbox}`);
      return
    }
    if (!isFiltered && currentFilter === 0 && searchbox?.length === 0) {
      getDonors(`donor?page=${page}`)
    }
  }

  const openMenu = event => {
    if (currentFilter !== 0) {
      setcurrentFilter(0)
    }
    setAnchor(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchor(null)
  }

  const handleApplyFilter = () => {
    setsearchbox('')
    let filterurl = '&custom'
    if (searchIsDefer?.length) {
      let deftype = 'Fit'
      if (searchIsDefer === 'Partial') {
        deftype = 1
      }
      if (searchIsDefer === 'Permanent') {
        deftype = 2
      }
      filterurl = filterurl + `&isdefer=${deftype}`
    }
    if (searchGender?.length) {
      filterurl = filterurl + `&gender=${searchGender}`
    }
    if (searchSite?.length) {
      filterurl = filterurl + `&site=${searchSite}`
    }
    if (searchCamp) {
      filterurl = filterurl + `&camp_id=${searchCamp?.id}`
    }
    if (searchDonationType?.length) {
      filterurl = filterurl + `&donationType=${searchDonationType}`
    }
    if (searchDateFrom?.length) {
      filterurl = filterurl + `&startDate=${searchDateFrom}`
    }
    if (searchDateTo?.length) {
      filterurl = filterurl + `&endDate=${searchDateTo}`
    }
    if (searchAgeFrom?.length) {
      filterurl = filterurl + `&startAge=${searchAgeFrom}`
    }
    if (searchAgeTo?.length) {
      filterurl = filterurl + `&endAge=${searchAgeTo}`
    }
    if (searchLocation?.length > 0) {
      let arr = searchLocation.join('-')
      filterurl = filterurl + `&location=${arr}`
    }
    if (sorting && sorting?.length > 0) {
      if (sorting[0]?.id) {
        filterurl = filterurl + `&${sorting[0]?.id}=${sorting[0]?.desc ? "desc" : "asc"}`
      }
    }
    if (currentFilter) {
      filterurl = filterurl + `&status=${currentFilter}`;
    }
    if (filterurl === "&custom") {
      filterurl = "";
    }



    setCustomUrl(filterurl)
    // if(searchbox){
    //   filterurl = filterurl + `& key=${ searchSelect }& value=${ searchbox } `
    // }
    setCurrSearchPrams({
      searchIsDefer: searchIsDefer,
      searchDonationType: searchDonationType,
      searchGender: searchGender,
      searchSite: searchSite,
      searchAgeFrom: searchAgeFrom,
      searchAgeTo: searchAgeTo,
      searchDateFrom: searchDateFrom,
      searchDateTo: searchDateTo,
      searchLocation: searchLocation,
      searchCamp: searchCamp
    })

    closeMenu();
    setIsFiltered(filterurl ? true : false);
    getDonors(`donor?` + filterurl)
  }

  const handleChange = (event, object) => {
    const {
      target: { value }
    } = event
    setSearchLocation(
      //On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    )
  }

  const handleLocationCancel = name => {
    let locationname = searchLocation.filter(function (n) {
      return n !== name
    })
    setClosefltr(true)
    setSearchLocation(locationname)
  }
  const handleStatusChange = (event, object) => {
    console.log(event)
    const {
      target: { value }
    } = event
    setSearchPrecessStatus(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    )
  }

  const handleStatusCancel = i => {
    let pstatus = searchProcessStatus.filter(function (n) {
      return n !== i
    })
    setSearchPrecessStatus(pstatus)
  }

  useEffect(() => {
    if (isFiltered && closefltr) {
      handleApplyFilter()
    }
    setClosefltr(false)
  }, [
    searchIsDefer,
    searchDonationType,
    searchGender,
    searchSite,
    searchAgeFrom,
    searchAgeTo,
    searchDateFrom,
    searchDateTo,
    searchLocation,
    searchCamp
  ])

  const updateAll = useCallback(
    debouncing((searchValue) => {
      getDonors(
        `donor?${searchValue ?`key=${searchSelect}&value=${searchValue}`: ""} `
      );
    }, 500),
    [searchSelect]
  )

  return (
    <>
      <Loader isLoading={isLoading} />
        <BreadCrumb breadcrumbRoute={breadcrumbRoute} />
        <ScrollCard normal>
      <div id='upper_div_filter'>
          <div className="my-auto">
            <div className="donor-inventory-search" >
              <div
                className="search-and-filter-container align-items-center"
                ref={reff}

              >
                <div className="search-container add-dnr-search paddingnone" style={{ paddingLeft: "0 !important" }}>

                  <Select
                    sx={searchSelectBox}
                    value={searchSelect}
                    onChange={e => {
                      setLocalStorageItem("donorSearchBy", e.target.value)
                      setcurrentFilter(0)
                      setsearchbox('')
                      setSearchSelect(e.target.value)
                      resetFilterValues()
                      getDonors(`donor ? page = 1`);
                    }}
                  >
                    <MenuItem value='bag_number'>Search By Blood Bag </MenuItem>
                    <MenuItem value='name'>Search By Donor Name </MenuItem>
                    <MenuItem value='adharcard'>Search By Aadhaar</MenuItem>
                    <MenuItem value='mobile'>Search By Mobile Number</MenuItem>
                  </Select>
                  <input
                    value={searchbox}
                    onFocus={() => setcurrentFilter(0)}
                    onChange={e => {
                      resetFilterValues()
                      setsearchbox(e.target.value)
                      updateAll(e.target.value)
                    }}
                    className='search-input'
                    type='text'
                    placeholder='Search...'
                  />
                  <img
                    src={'../../assets/images/setting-5.svg'}
                    id='basic-button'
                    onClick={openMenu}
                    alt='search-icon'
                  />
                  <Menu
                    open={Boolean(anchor)}
                    anchorEl={anchor}
                    onClose={closeMenu}
                    keepMounted
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center'
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'center'
                    }}

                    //TransitionComponent={Slide}
                  >
                    <div className='fltr-container'>
                      <div className='row mx-widthh'>
                        <div className='fltrlabel-div'>
                          <FormLabel sx={labelStyles}>Is Defered</FormLabel>
                        </div>
                        <div className='col-sm-12   col-md-8'>
                          <Select
                            sx={inputStyles}
                            fullWidth
                            select
                            placeholder='Enter District'
                            name='is_defer'
                            value={searchIsDefer}
                            onChange={e => setSearchIsdefer(e.target.value)}
                          >
                            <MenuItem value='fit'>Fit</MenuItem>
                            <MenuItem value='Partial'>Partial</MenuItem>
                            <MenuItem value='Permanent'>Permanent</MenuItem>
                          </Select>
                        </div>
                      </div>
                      <div className='row mx-widthh'>
                        <div className='fltrlabel-div'>
                          <FormLabel sx={labelStyles}>Gender</FormLabel>
                        </div>
                        <div className='col-md-8'>
                          <Select
                            sx={inputStyles}
                            fullWidth
                            select
                            placeholder='Enter District'
                            name='gender'
                            value={searchGender}
                            onChange={e => setSearchGender(e.target.value)}
                          >
                            <MenuItem value='male'>Male</MenuItem>
                            <MenuItem value='female'>Female</MenuItem>
                          </Select>
                        </div>
                      </div>
                      <div className='row mx-widthh'>
                        <div className='fltrlabel-div'>
                          <FormLabel sx={labelStyles}>Donation Site/Place</FormLabel>
                        </div>
                        <div className='col-md-8'>
                          <Select
                            sx={inputStyles}
                            fullWidth
                            select
                            placeholder='Enter District'
                            name='site'
                            value={searchSite}
                            onChange={e => setSearchSite(e.target.value)}
                          >
                            <MenuItem value='blood_bank'>Blood Bank</MenuItem>
                            <MenuItem value='camp'>Blood Camp</MenuItem>
                          </Select>
                        </div>
                      </div>
                      {searchSite === 'camp' ? (
                        <div className='row mx-widthh'>
                          <div className='fltrlabel-div'>
                            <FormLabel sx={labelStyles}>Camp Name</FormLabel>
                          </div>
                          <div className='col-md-8'>
                            <Select
                              sx={inputStyles}
                              fullWidth
                              select
                              placeholder='Enter District'
                              name='site'
                              value={searchCamp?.camp_name}
                              onChange={e => setSearchCamp(e.target.value)}
                            >
                              {campList?.length > 0 ? (
                                campList?.map(ele => <MenuItem value={ele}>{ele?.camp_name}</MenuItem>)
                              ) : (
                                <MenuItem value='Others'>Others</MenuItem>
                              )}
                            </Select>
                          </div>
                        </div>
                      ) : null}
                      <div className='row mx-widthh'>
                        <div className='fltrlabel-div'>
                          <FormLabel sx={labelStyles}>Donation Type</FormLabel>
                        </div>
                        <div className='col-md-8'>
                          <Select
                            sx={inputStyles}
                            fullWidth
                            select
                            placeholder='donation_type'
                            name='district'
                            value={searchDonationType}
                            onChange={e => setSearchDonationType(e.target.value)}
                          >
                            <MenuItem value='voluntary'>Voluntary Donor</MenuItem>
                            <MenuItem value='replacement'>Replacement Donor</MenuItem>
                            <MenuItem value='autologous'>Autologous Donor</MenuItem>
                          </Select>
                        </div>
                      </div>
                      <div className='row mx-widthh'>
                        <div className='fltrlabel-div'>
                          <FormLabel sx={labelStyles}>Location</FormLabel>
                        </div>
                        <div className='col-md-8'>
                          <Select
                            sx={inputStyles}
                            multiple
                            fullWidth
                            InputLabelProps={{ shrink: false }}
                            value={searchLocation}
                            onChange={e => {
                              handleChange(e)
                              console.log(searchLocation)
                            }}
                            // input={<OutlinedInput label="Tag" />}
                            renderValue={selected => selected.join(',')}
                            //MenuProps={inputStyles}
                          >
                            {districts.length > 0 ? (
                              districts.map((dist, i) => (
                                <MenuItem key={i} value={dist?.name}>
                                  <Checkbox sx={radioStyles} checked={searchLocation.indexOf(dist?.name) > -1} />
                                  <ListItemText primary={dist?.name} />
                                </MenuItem>
                              ))
                            ) : (
                              <MenuItem value=''>No District Found</MenuItem>
                            )}
                          </Select>
                          <div className='filter-tab-cont'>
                            {searchLocation?.length > 0
                              ? searchLocation?.map((n, i) => (
                                  <span className='filter-tab-div'>
                                    {n}
                                    <HighlightOffIcon
                                      sx={{
                                        width: '24px',
                                        height: '24px',
                                        color: '#214F8A'
                                      }}
                                      onClick={() => handleLocationCancel(n)}
                                    />
                                  </span>
                                ))
                              : null}
                          </div>
                        </div>
                      </div>

                      <div className='row mx-widthh'>
                        <div className='fltrlabel-div'>
                          <FormLabel sx={labelStyles}>Date</FormLabel>
                        </div>
                        <div className='col-md-4 col-sm-12 inpt'>
                          <DatePicker
                            sx={inputStyles}
                            fullWidth
                            type='date'
                            format='DD/MM/YYYY'
                            name='startdate'
                            value={searchDateFrom}
                            onChange={value => setSearchDateFrom(dayjs(value).format('YYYY-MM-DD'))}
                          />
                        </div>
                        <div className='col-md-4 col-sm-12 inpt'>
                          <DatePicker
                            sx={inputStyles}
                            fullWidth
                            type='date'
                            format='DD/MM/YYYY'
                            name='enddate'
                            value={searchDateTo}
                            onChange={value => setSearchDateTo(dayjs(value).format('YYYY-MM-DD'))}
                          />
                        </div>
                      </div>
                      <div className='row mx-widthh'>
                        <div className='fltrlabel-div'>
                          <FormLabel sx={labelStyles}>Age Group</FormLabel>
                        </div>
                        <div className='col-md-4 col-sm-12 inpt'>
                          <TextField
                            sx={inputStyles}
                            fullWidth
                            type='number'
                            name='agefrom'
                            value={searchAgeFrom}
                            onChange={e => setSearchAgeFrom(e.target.value)}
                          />
                        </div>
                        <div className='col-md-4 com-sm-12 inpt'>
                          <TextField
                            sx={inputStyles}
                            fullWidth
                            type='number'
                            name='ageto'
                            value={searchAgeTo}
                            onChange={e => setSearchAgeTo(e.target.value)}
                          />
                        </div>
                      </div>
                      {/* <div className="row mx-widthh">
                        <div className="fltrlabel-div">
                          <FormLabel sx={labelStyles}>Text</FormLabel>
                        </div>
                        <div className="col-md-8">
                          <TextField
                            sx={inputStyles}
                            fullWidth
                            type="text"
                            placeholder="Enter Text"
                            name="searh_text"
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                          />
                        </div>
                      </div> */}

                      <div className="row pb-4">
                        <div className="fltraction d-flex flex-row-reverse">
                          <button
                            className="btn gradient-btn"
                            onClick={handleApplyFilter}
                          >
                            Apply Filter
                          </button>
                          <button
                            className="btn backBtn "
                            onClick={resetFilterMain}
                          >
                            Reset
                          </button>
                        </div>

                      </div>
                    </div>
                  </Menu>
                </div>
              </div>

              <div style={{ display: "flex", gap: "16px" }}>
                <ExcelAndPdfDownload baseUrl='donor' name="Donor Register" pdf={true} excel={true} type={"donor_register"} sortingOnColumnOptions={sortingOnColumnOptions} />

                {checkPermission('CREATE_DONORS') && (
                  <button
                    type='button'
                    className='btn gradient-btn'
                    onClick={() => {
                      history.push('/donors/add')
                    }}
                  >
                    <Add /> Donor Registration
                  </button>
                )}
              </div>
            </div>
          </div>

          <Collapse in={isFiltered}>
            <div style={{ gap: '10px' }} className='d-flex flex-wrap align-items-center pb-3'>
              {currSearchPrams?.searchIsDefer ? (
                <span className='filter-tab-div '>
                  {currSearchPrams?.searchIsDefer}
                  <HighlightOffIcon
                    sx={{
                      width: '24px',
                      height: '24px',
                      color: '#214F8A'
                    }}
                    onClick={() => {
                      setSearchIsdefer('')
                      setClosefltr(true)
                    }}
                  />
                </span>
              ) : null}
              {currSearchPrams?.searchGender ? (
                <span className='filter-tab-div'>
                  {currSearchPrams?.searchGender}
                  <HighlightOffIcon
                    sx={{
                      width: '24px',
                      height: '24px',
                      color: '#214F8A'
                    }}
                    onClick={() => {
                      setSearchGender('')
                      setClosefltr(true)
                    }}
                  />
                </span>
              ) : null}
              {currSearchPrams?.searchSite ? (
                <span className='filter-tab-div'>
                  {currSearchPrams?.searchSite === 'blood_bank' ? 'Blood Bank' : 'Blood Camp'}
                  <HighlightOffIcon
                    sx={{
                      width: '24px',
                      height: '24px',
                      color: '#214F8A'
                    }}
                    onClick={() => {
                      setSearchSite('')
                      setClosefltr(true)
                    }}
                  />
                </span>
              ) : null}
              {currSearchPrams?.searchCamp ? (
                <span className='filter-tab-div'>
                  {currSearchPrams?.searchCamp?.camp_name}
                  <HighlightOffIcon
                    sx={{
                      width: '24px',
                      height: '24px',
                      color: '#214F8A'
                    }}
                    onClick={() => {
                      setSearchCamp('')
                      setClosefltr(true)
                    }}
                  />
                </span>
              ) : null}
              {currSearchPrams?.searchDonationType ? (
                <span className='filter-tab-div'>
                  {currSearchPrams?.searchDonationType}
                  <HighlightOffIcon
                    sx={{
                      width: '24px',
                      height: '24px',
                      color: '#214F8A'
                    }}
                    onClick={() => {
                      setSearchDonationType('')
                      setClosefltr(true)
                    }}
                  />
                </span>
              ) : null}

              {currSearchPrams?.searchDateFrom ? (
                <span className='filter-tab-div'>
                  {dayjs(currSearchPrams?.searchDateFrom).format('DD/MM/YYYY')}

                  <HighlightOffIcon
                    sx={{
                      width: '24px',
                      height: '24px',
                      color: '#214F8A'
                    }}
                    onClick={() => {
                      setSearchDateFrom('')
                      setClosefltr(true)
                    }}
                  />
                </span>
              ) : null}

              {searchDateTo?.length ? (
                <span className='filter-tab-div'>
                  {dayjs(searchDateTo).format('DD/MM/YYYY')}
                  <HighlightOffIcon
                    sx={{
                      width: '24px',
                      height: '24px',
                      color: '#214F8A'
                    }}
                    onClick={() => {
                      setSearchDateTo('')
                      setClosefltr(true)
                    }}
                  />
                </span>
              ) : null}
              {currSearchPrams?.searchAgeFrom ? (
                <span className='filter-tab-div'>
                  Age From : {currSearchPrams?.searchAgeFrom}
                  <HighlightOffIcon
                    sx={{
                      width: '24px',
                      height: '24px',
                      color: '#214F8A'
                    }}
                    onClick={() => {
                      setSearchAgeFrom()
                      setClosefltr(true)
                    }}
                  />
                </span>
              ) : null}
              {currSearchPrams?.searchAgeTo ? (
                <span className='filter-tab-div'>
                  Age To : {currSearchPrams?.searchAgeTo}
                  <HighlightOffIcon
                    sx={{
                      width: '24px',
                      height: '24px',
                      color: '#214F8A'
                    }}
                    onClick={() => {
                      setSearchAgeTo()
                      setClosefltr(true)
                    }}
                  />
                </span>
              ) : null}
              <div className='filter-tab-cont'>
                {currSearchPrams?.searchLocation?.length > 0
                  ? currSearchPrams?.searchLocation?.map((n, i) => (
                      <span className='filter-tab-div'>
                        {n}
                        <HighlightOffIcon
                          sx={{
                            width: '24px',
                            height: '24px',
                            color: '#214F8A'
                          }}
                          onClick={() => handleLocationCancel(n)}
                        />
                      </span>
                    ))
                  : null}
              </div>

              {currSearchPrams?.searchGender?.length ||
              currSearchPrams?.searchLocation?.length > 0 ||
              currSearchPrams?.CollapsesearchAgeTo?.length ||
              currSearchPrams?.searchAgeFrom?.length ||
              currSearchPrams?.searchDateFrom?.length ||
              currSearchPrams?.searchDateTo?.length ||
              currSearchPrams?.searchIsDefer?.length ||
              currSearchPrams?.searchSite?.length ||
              currSearchPrams?.searchDonationType?.length ? (
                <>
                  <div className='filter-tab-cont'>
                    <span className='filter-tab-div'>Total Records: {pageData?.totalRecord || '0'}</span>
                  </div>
                  <div
                    style={{
                      color: '#214F8A',
                      fontSize: '1rem',
                      cursor: 'pointer'
                    }}
                    onClick={resetFilterMain}
                  >
                    Clear Filter
                  </div>
                </>
              ) : null}
            </div>
          </Collapse>
         </div>
          <AllDonorsTable
            data={data}
            currentFilter={currentFilter}
            setcurrentFilter={setcurrentFilter}
            getDonors={handleCustomRequests}
            sorting={sorting}
            setSorting={setSorting}
            isFiltered={isFiltered}
          />
          {pageData && pageData.totalPage > 1 && (
            <div className='float-right mt-1'>
              <PaginationLink
                pagination={pageData}
                getNext={page => {
                  handleCustomRequests(page)
                }}
              />
            </div>
          )}
        </ScrollCard>
    </>
  )
}

export default Donors
