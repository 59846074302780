export const checkSpecialString = (inputObject) => {
  console.log('inputObject', inputObject);
  const regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
  if (Object.keys(inputObject).length < 0) return false;

  for (const item of Object.keys(inputObject)) {
    const value = inputObject[item];
    if (value == '') {
      return { err: true, message: `${item} should not be empty` };
    }

    if (regex.test(value)) {
      return { err: true, message: `${item} should not contain special character` };
    }
  }
  return false;
};

export const censorDigits = (n) => {
  if (!n) {
    return null;
  }
  const stringDigits = String(n);
  let trimmingSize = 4;

  if (stringDigits.length <= 4) {
    trimmingSize = Math.floor(stringDigits.length / 2);
  }

  const remaining = stringDigits.length - trimmingSize;

  let first = stringDigits.substring(0, remaining);
  const last = stringDigits.substring(remaining, stringDigits.length);

  first = first
    .split('')
    .map((item) => (item = '*'))
    .join('');

  const res = first + last;

  if (res === '0') {
    return null;
  }

  return res;
};

export const CROSSMATCH_METHODS = [
  {
    value: 'not_applicable',
    label: 'Not Applicable',
  },
  {
    value: 'saline',
    label: 'Saline',
  },
  {
    value: 'gel_card',
    label: 'Coombs at 37 °C',
  },
  {
    value: 'manual',
    label: 'Manual',
  },
];
