// ** MUI Imports
import {
  FiberManualRecord,
  MoreVert,
  NotificationsNoneOutlined,
  SettingsOutlined,
  UsbRounded
} from '@mui/icons-material'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import { useState } from 'react'
import { ListItemIcon, MenuItem, Tooltip, Menu } from '@mui/material'
import { useHistory } from 'react-router-dom'
import ToolTips from '../../app/common/ToolTips'
import { getName } from '../../app/middleware/auth'
import { useAppContext } from '../../AppContext'
import Tutorials from '../../app/shared/Tutorials'

function AppBarContent(props) {
  // ** Props
  const { hidden, settings, saveSettings, toggleNavVisibility } = props

  // ** States
  const [anchorEl, setAnchorEl] = useState(null)
  const [userEl, setUserEl] = useState(null)
  const [notiEl, setNotiEl] = useState(null)
  const [small, setSmall] = useState(false)
  const history = useHistory()
  const { navCollapsed } = settings

  const handleDropdownOpen = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleNotiDropdownOpen = event => {
    setNotiEl(event.currentTarget)
  }
  const handleUserDropdownOpen = event => {
    setUserEl(event.currentTarget)
  }

  const handleDropdownClose = () => {
    setAnchorEl(null)
    setUserEl(null)
    setNotiEl(null)
  }

  const { setTtiOpen, setDonorOpen } = useAppContext()

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background: '#fff',
        borderRadius: '8px',
        py: 1
      }}
    >
      <Box className='actions-left' sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
        {hidden ? (
          <IconButton color='inherit' sx={{ ml: 1.75 }} onClick={toggleNavVisibility}>
            <img
              type='button'
              className='menu-logo'
              src={navCollapsed ? '/assets/images/navbar/toggle.png' : '/assets/images/menu_icon.svg'}
              alt='menu logo'
            />
          </IconButton>
        ) : null}
      </Box>
      <Box
        className='actions-right'
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '4px',
          pr: 1
        }}
      >
        <Tutorials />
        <Tooltip title='Home'>
          <IconButton
            onClick={() => {
              history.push('/dashboard')
            }}
          >
            <img src='/assets/images/navbar/home.png' alt='delete icon' />
          </IconButton>
        </Tooltip>

        <Tooltip title='Admin Settings'>
          <IconButton
            onClick={() => {
              history.push('/admin-settings')
            }}
          >
            <SettingsOutlined sx={{ fontSize: '1.8rem' }} />
          </IconButton>
        </Tooltip>
        <Tooltip title='Notification'>
          <IconButton onClick={handleNotiDropdownOpen}>
            <NotificationsNoneOutlined sx={{ fontSize: '1.8rem' }} />
          </IconButton>
        </Tooltip>

        <Menu
          anchorEl={notiEl}
          open={Boolean(notiEl)}
          onClose={() => handleDropdownClose()}
          sx={{ '& .MuiMenu-paper': { width: 220, mt: 1 } }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MenuItem
            sx={{ pl: 2 }}
            onClick={() => {
              handleDropdownClose()
            }}
          >
            No Notifications
          </MenuItem>
        </Menu>
        <div className=' nav_right' onClick={handleUserDropdownOpen}>
          <img src='/assets/images/avatars/1.png' alt='' />
          <ToolTips title={getName()} id='user-1' placement='bottom'>
            <span className='text-ellipsis'>{getName()}</span>
          </ToolTips>

          <div>
            <IconButton>
              <MoreVert />
            </IconButton>
          </div>
        </div>

        <Menu
          anchorEl={userEl}
          open={Boolean(userEl)}
          onClose={() => handleDropdownClose()}
          sx={{ '& .MuiMenu-paper': { width: 220, mt: 1 } }}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MenuItem
            sx={{ pl: 2 }}
            onClick={() => {
              handleDropdownClose()
              history.push('/change-password')
            }}
          >
            <ListItemIcon>
              <FiberManualRecord sx={{ fontSize: '10px' }} />
            </ListItemIcon>
            Change Password
          </MenuItem>

          <MenuItem
            sx={{ pl: 2 }}
            onClick={() => {
              handleDropdownClose()
              localStorage.removeItem('authInfo')
              // Remove the iframe if it exists
              const iframe = document.querySelector('iframe');
              if (iframe) {
                document.body.removeChild(iframe);
              } else {
                console.log('No iframe found');
              }
            
              // Further logout logic like redirect or clearing session
              window.location.replace('/login')

            }}
          >
            <ListItemIcon>
              <FiberManualRecord sx={{ fontSize: '10px' }} />
            </ListItemIcon>{' '}
            Log Out
          </MenuItem>
        </Menu>
        {/* <NotificationDropdown settings={settings} />
        <UserDropdown settings={settings} /> */}
      </Box>
    </Box>
  )
}

export default AppBarContent
